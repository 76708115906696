import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "satellite-access-requests"
    }}>{`Satellite Access Requests`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Satellite Access Requests provide an easy way to determine when Capella's satellites will be able to view and potentially image areas of interest. Similar to a satellite tasking rquest, you will need to know where your area of interest is located, when you would like the data to be collected, and any imaging constraints you would like to apply (e.g. viewing geometry). Once an access request has been submitted, Capella's system will calculate when the satellite constellation can access the target and the relevant properties for that access. Responses to access requests do not currently consider conflicts with tasking requests that may be in the imaging schedule and should not be interpretted as confirmation that the satellite can image the area of interest in the specified time. Access responses only provide an indication that the constellation is physically able to image the target.`}</p>
    <h2 {...{
      "id": "submitting-an-access-request"
    }}>{`Submitting an Access Request`}</h2>
    <p>{`To submit an access request, you will submit a request to the access requests endpoint. The request body is a GeoJSON feature, which includes properties describing the access request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/ma/accessrequests/

{
  "geometry": {
    "type": "Point",
    "coordinates": [
      -122.434856,
      37.754422
    ]
  },
  "properties": {
    "accessrequestDescription": "San Francisco",
    "accessrequestName": "San Francisco",
    "windowOpen": "2022-11-15T00:00:00.000Z",
    "windowClose": "2022-11-20T00:00:00.000Z",
    "accessConstraints": {
      "lookDirection": "right",
      "ascDsc": "ascending",
      "localTime": [
        [
          0,
          86400
        ]
      ],
      "offNadirMin": 5,
      "offNadirMax": 45,
      "imageWidth": 5000,
      "imageLength": 5000
    }
  },
  "type": "Feature"
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`geometry`}</inlineCode>{` can be either a point or polygon. Multi-polygons and intersecting polygons are not accepted. The `}<inlineCode parentName="p">{`accessrequestName`}</inlineCode>{` and `}<inlineCode parentName="p">{`accessrequestDescription`}</inlineCode>{` can be used to help characterize and describe the tasking request. The remaining properties define how the access request will be evaluated:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` is a required field that should be set at the earliest time (UTC) you would like to evaluate accesses. It cannot be more than 90 days from the current date.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` is the end time (UTC) for the window within which you would like to evaluate accesses. It cannot be more than 31 days from `}<inlineCode parentName="li">{`windowOpen`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessConstraints`}</inlineCode>{` are the description of constraints for imaging the area of interest. `}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`lookDirection`}</inlineCode>{` can be either `}<inlineCode parentName="li">{`right`}</inlineCode>{` or `}<inlineCode parentName="li">{`left`}</inlineCode>{` and indicates the direction of imaging.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ascDsc`}</inlineCode>{` indicates whether the orbit direction should be constrained to `}<inlineCode parentName="li">{`ascending`}</inlineCode>{` or `}<inlineCode parentName="li">{`descending`}</inlineCode>{` orbits only.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`localTime`}</inlineCode>{` is the local astronomical time.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`offNadirMin`}</inlineCode>{` is the minimum desired off nadir angle in degrees.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`offNadirMax`}</inlineCode>{` is the maximum desired off nadir angle in degrees.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`imageWidth`}</inlineCode>{` is the desired width of the access in meters.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`imageLength`}</inlineCode>{` is the desired length of the access in meters.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`The response body will contain the detailed information about your submitted access request, including the `}<inlineCode parentName="p">{`accessrequestId`}</inlineCode>{`, which you will need to check the status of the request and fetch the calculated accesses.`}</p>
    <h2 {...{
      "id": "getting-the-status-of-an-access-request"
    }}>{`Getting the Status of an Access Request`}</h2>
    <p>{`Access requests are evaluated asyncronously and you will need to check that the processing has been completed before requesting the calculated accesses. To check the status of the tasking request, you will use the `}<inlineCode parentName="p">{`accessrequestId`}</inlineCode>{` with the access requests endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/ma/accessrequests/{{accessrequestId}}
`}</code></pre>
    <h2 {...{
      "id": "getting-satellite-accesses-for-the-request"
    }}>{`Getting Satellite Accesses for the Request`}</h2>
    <p>{`When the `}<inlineCode parentName="p">{`processingStatus`}</inlineCode>{` of an access request is `}<inlineCode parentName="p">{`completed`}</inlineCode>{` and the `}<inlineCode parentName="p">{`accessibilityStatus`}</inlineCode>{` is `}<inlineCode parentName="p">{`accessible`}</inlineCode>{`, you can make a request to the accesses endpoint to find the details of the satellite accesses for your area of interest:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/ma/accesses/{{accessrequestId}}
`}</code></pre>
    <p>{`The response will contain detailed information about each access:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "center": [
        -114.08203100399066,
        35.173604153557186,
        1010.7208099365234
    ],
    "centerEcef": [
        -2130064.7879956863,
        -4765828.834219071,
        3654209.2659127866
    ],
    "spacecraftId": 10,
    "accessId": "64b11c0e-14b5-4975-80cc-02dc5b83d0af",
    "accessrequestId": "68ee96d0-786c-4458-821b-7b53e0f05442",
    "tileId": "81ba67d7-2adb-4367-8d87-00c59b3a0591",
    "windowOpen": "2023-08-25T01:21:50.043Z",
    "windowClose": "2023-08-25T01:25:20.532Z",
    "windowOpenLocal": "2023-08-24T18:21:50.043Z",
    "windowCloseLocal": "2023-08-24T18:25:20.532Z",
    "accessProperties": {
        "ascdsc": "ascending",
        "lookDirection": "right",
        "localTime": 64017,
        "azimuthOpen": 267.2416864320832,
        "azimuthClose": 41.638880814542546,
        "elevationMin": 33.217551,
        "elevationMax": 60.790342,
        "offNadirMin": 26.539254,
        "offNadirMax": 49.99992
    },
    "orbitalPlane": 45
}
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`center`}</inlineCode>{` is the center coordinate of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`centerEcef`}</inlineCode>{` is the earth-centered, earth-fixed center coordinate of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`spacecraftId`}</inlineCode>{` is the identifier of the spacecraft for the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessId`}</inlineCode>{` is the unique identifier of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessrequestId`}</inlineCode>{` is the parent access request identifier of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileId`}</inlineCode>{` is the unique identifier of the tile associated with the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` is the potential start time of imaging (UTC).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` is the potential end time of imaging (UTC).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpenLocal`}</inlineCode>{` is the potential start time of imaging (Local target time).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowCloseLocal`}</inlineCode>{` is the potential end time of imaging (Local target time).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessProperties`}</inlineCode>{` describe the specific properties for potential imaging:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ascDsc`}</inlineCode>{` indicates whether the orbit direction should be constrained to `}<inlineCode parentName="li">{`ascending`}</inlineCode>{` or `}<inlineCode parentName="li">{`descending`}</inlineCode>{` orbits only.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`lookDirection`}</inlineCode>{` can be either `}<inlineCode parentName="li">{`right`}</inlineCode>{` or `}<inlineCode parentName="li">{`left`}</inlineCode>{` and indicates the direction of imaging.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`localTime`}</inlineCode>{` is the local astronomical time.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`azimuthOpen`}</inlineCode>{` is the counter clockwise angle with respect to the azimuth open in the topocentric geodetic ENZ coordinate system.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`azimuthClose`}</inlineCode>{` is the counter clockwise angle with respect to the azimuth close in the topocentric geodetic ENZ coordinate system.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`elevationMin`}</inlineCode>{`, also known as the minimum grazing angle, is the minimum angle between the tangent to the surface and the line of sight of the satellite as seen from center point during the access window. `}</li>
          <li parentName="ul"><inlineCode parentName="li">{`elevationMax`}</inlineCode>{`, also known as the maximum grazing angle, is the maximum angle between the tangent to the surface and the line of sight of the satellite as seen from center point during the access window.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`offNadirMin`}</inlineCode>{` is the minimum desired off nadir angle in degrees.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`offNadirMax`}</inlineCode>{` is the maximum desired off nadir angle in degrees.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`orbitalPlane`}</inlineCode>{` is the orbital plane of the satellite for the access.`}</li>
    </ul>
    <h2 {...{
      "id": "getting-satellite-access-footprints-for-the-request"
    }}>{`Getting Satellite Access Footprints for the Request`}</h2>
    <p>{`The predicted footprint geometries of accesses for an access request are available through the tiles/list/accessrequest endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/tiles/list/accessrequest/{{accessrequestId}}
`}</code></pre>
    <p>{`The response will contain detailed information about each access as a collection of features in GeoJSON format:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [
          -122.41326408138187,
          37.780990673518104,
          14.750692974725553
        ],
        [
          -122.46862312789796,
          37.7713211514591,
          76
        ],
        [
          -122.4564324099444,
          37.72738299274747,
          98.80231761025306
        ],
        [
          -122.40110437955961,
          37.73704691802875,
          35.79287682063475
        ],
        [
          -122.41326408138187,
          37.780990673518104,
          14.750692974725553
        ]
      ]
    ]
  },
  "properties": {
    "accessrequestId": "6eeb1e91-392d-4574-a153-b98304cc3497",
    "taskingrequestId": null,
    "repeatrequestId": null,
    "tileId": "197ad0e5-e1e2-46c6-913f-dcc399c12983",
    "tileGroupId": "bb020f0b-e8ce-4844-858a-19b29bd03c27",
    "tileWidth": 5000,
    "tileLength": 5000,
    "tileArea": 25000000
  }
}
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` identifies the contents as a GeoJSON feature.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`geometry`}</inlineCode>{` is the polygon boundary of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessrequestId`}</inlineCode>{` is the parent access request identifier of the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`taskingrequestId`}</inlineCode>{` will always be null for accessrequest tiles.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repeatrequestId`}</inlineCode>{` will always be null for accessrequest tiles.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileId`}</inlineCode>{` is the unique identifier of the tile associated with the access.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileGroupId`}</inlineCode>{` is the unique identified of the tile group associated with the access opportunity.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileWidth`}</inlineCode>{` is the width of the tile in meters.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileLength`}</inlineCode>{` is the length of the tile in meters.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tileArea`}</inlineCode>{` is the area of the tile in square meters.`}</li>
    </ul>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on utilizing Mission Awareness, see the Support article for `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/10592729777556-What-is-Mission-Awareness"
      }}>{`What is Mission Awareness?`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      